import { Grid } from '@mui/material';
import Header from '../../components/header';

interface IDefaultLayoutProps {
  children: React.ReactNode;
}

const DefaultLayout: React.FC<IDefaultLayoutProps> = function ({ children }) {
  return (
    <>
      <Header />
      <Grid container paddingX="12px" paddingY="12px">
        {children}
      </Grid>
      ;
    </>
  );
};

export default DefaultLayout;
