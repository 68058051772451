import React from 'react';
import { DataGrid, GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import { formatDate } from '../../../validators/fomatter';
import { Box, Grid } from '@mui/material';
import ICobrancaModel from '../../../../interfaces/ICobrancaModel';
import { Formik } from 'formik';
import './style.css';

interface ILogTableProps {
  data: ICobrancaModel;
}

interface IRowCobrancaGateway {
  identificadorCobranca: string;
  valor: number;
  dataCobranca: Date;
  situacaoCobranca: string;
  dataPagamento: Date;
  dataUltimaTentativa: Date;
  numeroTentativa: number;
  informacaoAdicional: string;
  token: string;
  dataGeracao: Date;
  bandeiraCartao: string;
  situacaoTokenCobranca: string;
  codigoRetornoGateway: string;
  mensagemRetornoGateway: string;
  capturado: string;
  nsu: string;
  tid: string;
  codigoAutorizacao: string;
  nomeGateway: string;
}

const CobrancaGatewayTable: React.FC<ILogTableProps> = function ({ data }) {
  console.log(data);
  const rows: IRowCobrancaGateway[] = data.cobrancaGateway.map((item: any) => ({
    identificadorCobranca: data.identificadorCobranca,

    valor: data.valor,
    dataCobranca: data.dataCobranca,
    situacaoCobranca: data.situacao,
    dataPagamento: data.dataPagamento,
    dataUltimaTentativa: data.dataUltimaTentativa,

    numeroTentativa: data.numeroTentativa,
    informacaoAdicional: data.informacaoAdicional,
    token: data.tokenCobranca.token,
    dataGeracao: data.tokenCobranca.dataGeracao,
    bandeiraCartao: data.tokenCobranca.bandeiraCartao,
    situacaoTokenCobranca: data.tokenCobranca.situacao,

    codigoRetornoGateway: item.codigoRetornoGateway,
    capturado: item.capturado? 'Ativo' : 'Inativo',
    nsu: item.nsu,
    tid: item.tid,
    codigoAutorizacao: item.codigoAutorizacao,
    nomeGateway: item.nomeGateway,

    mensagemRetornoGateway: item.mensagemRetornoGateway
  }));



  return (
    <>
      <Grid className="cobranca-container">
        {rows.map((item: any) => (
          <>
            <Grid
              item
              container
              xs={6}
              sm={4}
              className="cobranca-grid"
              key={item.identificadorCobranca}
            >
              <div>
                <b>Identificação Cobrança</b>
                <p className="cobranca-info">{item.identificadorCobranca}</p>
                <br />

                <b>Valor</b>
                <p className="cobranca-info">{item.valor}</p>
                <br />

                <b>Data de Cobrança</b>
                <p className="cobranca-info">{item.dataCobranca}</p>
                <br />

                <b>Situação da Cobrança</b>
                <p className="cobranca-info">{item.situacaoCobranca}</p>
                <br />

                <b>Data de Pagamento</b>
                <p className="cobranca-info">{item.dataPagamento}</p>
                <br />

                <b>Data da última Tentativa</b>
                <p className="cobranca-info">{item.dataUltimaTentativa}</p>
              </div>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={4}
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <div>
                <b>Número da tentativa</b>
                <p className="cobranca-info">{item.numeroTentativa}</p>
                <br />

                <b>Informação Adicional</b>
                <p className="cobranca-info">{item.informacaoAdicional}</p>
                <br />

                <b>Token</b>
                <p className="cobranca-info">{item.token}</p>
                <br />

                <b>Data de Geração</b>
                <p className="cobranca-info">{item.dataGeracao}</p>
                <br />

                <b>Bandeira do Cartão</b>
                <p className="cobranca-info">{item.bandeiraCartao}</p>
                <br />

                <b>Situação Token Cobrança</b>
                <p className="cobranca-info">{item.situacaoTokenCobranca}</p>
              </div>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={4}
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <div>
                <b>Código de Retorno do Gateway</b>
                <p className="cobranca-info">{item.codigoRetornoGateway}</p>
                <br />

                <b>Capturado</b>
                <p className="cobranca-info">{item.capturado}</p>
                <br />

                <b>NSU</b>
                <p className="cobranca-info">{item.nsu}</p>
                <br />

                <b>TID</b>
                <p className="cobranca-info">{item.tid}</p>
                <br />

                <b>Código de Autorização</b>
                <p className="cobranca-info">{item.codigoAutorizacao}</p>
                <br />

                <b>Nome do Gateway</b>
                <p className="cobranca-info">{item.nomeGateway}</p>
              </div>
            </Grid>
            {/* <Grid item container xs={12} sm={4}>
              <div>
                <p>
                  <b>Mensagem de Retorno do Gateway</b>
                </p>
                <p>{item.mensagemRetornoGateway}</p>
              </div>
            </Grid> */}
          </>
        ))}
      </Grid>
    </>
  );
};

export default CobrancaGatewayTable;
