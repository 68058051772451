import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import RequireAuth from './routes/requireAuth';
import ContextWrapper from './context';
import Home from './pages/home';
import config from './config/config';
import LoginPage from './pages/loginPage/index';
import ConsultarUsuarios from './pages/usuarios/consultarUsuarios/index';
import ConfigurarGateway from './pages/configuracoes/configurarGateway';
import Log from './pages/suporte/log';

function App() {
  return (
    <BrowserRouter basename={config.publicUrl}>
      <ContextWrapper>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/home" element={<RequireAuth><Home /></RequireAuth>} />
          <Route path="/usuarios/consultar-usuarios" element={<ConsultarUsuarios />} />
          <Route path="/configuracoes/configurar-gateway" element={<RequireAuth><ConfigurarGateway /></RequireAuth>} />
          <Route path="/suporte/log" element={<RequireAuth><Log /></RequireAuth>} />
        </Routes>
      </ContextWrapper>
    </BrowserRouter>
  );
}

export default App;
