import axios from 'axios';
import ICobrancaFilter from '../../interfaces/ICobrancaFilter';

const getCobrancas = async function (data: ICobrancaFilter) {
    const resp = await axios.get('Contas/Cobrancas', {
        params: data
    });
    return resp.data;
}

const logService = {
    getCobrancas
  }

  export default logService;