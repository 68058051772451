/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from 'react';
import Box from '@mui/material/Box';
import {
  DataGrid,
  GridActionsCellItem,
  gridClasses,
  GridColDef,
  GridValueFormatterParams,
  GridValueGetterParams
} from '@mui/x-data-grid';
import IListUserBackofficeResult from '../../../interfaces/listUserBackofficeResult';
import IListUserBackofficeFilter from '../../../interfaces/listUserBackofficeFilter';
import { Edit, Settings } from '@mui/icons-material';
import { Grid, Tooltip } from '@mui/material';
import roles from '../../../config/roles';
import { formatDate } from '../../validators/fomatter';
import UseUserContext from '../../../context/user';
import './style.css';
import { IUpdateUsers } from '../interface';
import { useState, useEffect } from 'react';
import sUser from '../../../services/userService';
import FormUser from '../formulario';
import Modal from '../../modal';

interface IUsuarioDataTableProps {
  data: IListUserBackofficeResult;
  setFilter: React.Dispatch<React.SetStateAction<IListUserBackofficeFilter>>;
  loading: boolean;
}

interface IRowUsers {
  nome: string;
  email: string;
  senhaInicial: string;
  ativo: boolean;
  dataCadastro?: Date;
  ultimaAlteracao?: Date;
  ultimoAcesso?: Date;
}

const UsersBackofficeTable: React.FC<IUsuarioDataTableProps> = function ({
  data,
  setFilter,
  loading
}) {
  const rows: IRowUsers[] = data.result.results.map((item: any) => ({
    id: item.idUsuario,
    nome: item.nome,
    email: item.email,
    senhaInicial: item.senhaInicial,
    ativo: item.ativo,
    dataCadastro: item.dataCadastro,
    ultimaAlteracao: item.ultimaAlteracao,
    ultimoAcesso: item.ultimoAcesso
  }));

  const columns: GridColDef<IRowUsers>[] = [
    {
      field: 'actions',
      headerClassName: 'super-app-theme--header',
      type: 'actions',
      width: 80,
      headerName: 'Ações',
      getActions: (params) => [
        <>
          <GridActionsCellItem
            key={params.id}
            icon={<Edit />}
            label="Editar"
            onClick={() => {
              const usuarioSelecionado = data.result.results.find(
                (item: any) => item.idUsuario == params.id
              )!;
              openEditModal({
                idUsuario: usuarioSelecionado.idUsuario,
                nome: usuarioSelecionado.nome,
                email: usuarioSelecionado.email,
                ativo: usuarioSelecionado.ativo,
                senhaInicial: usuarioSelecionado.senhaInicial
              });
            }}
          />
        </>
      ]
    },
    {
      field: 'nome',
      headerName: 'Nome',
      headerClassName: 'super-app-theme--header',
      minWidth: 200,
      flex: 0.5,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'email',
      headerName: 'E-mail',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      minWidth: 200,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'senhaInicial',
      headerClassName: 'super-app-theme--header',
      headerName: 'Senha Inicial',
      minWidth: 100,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'ativo',
      headerClassName: 'super-app-theme--header',
      headerName: 'Status',
      valueFormatter: ({ value }) => (value ? 'Ativo' : 'Inativo'),
      minWidth: 60,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'dataCadastro',
      headerClassName: 'super-app-theme--header',
      headerName: 'Data de Cadastro',
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        if (params.value != null) {
          return formatDate(params.value);
        }
      },
      minWidth: 80,
      flex: 0.5,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'ultimaAlteracao',
      headerName: 'Última alteração',
      headerClassName: 'super-app-theme--header',
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        if (params.value != null) {
          return formatDate(params.value);
        }
      },
      minWidth: 120,
      flex: 0.5,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'ultimoAcesso',
      headerName: 'Último Acesso',
      headerClassName: 'super-app-theme--header',
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        if (params.value != null) {
          return formatDate(params.value);
        }
      },
      minWidth: 120,
      flex: 0.5,
      align: 'center',
      headerAlign: 'center'
    }
  ];

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0
  });

  const [rowCountState, setRowCountState] = React.useState(data.result.totalRecords || 0);

  const [selectedUser, setSelectedUser] = useState<IUpdateUsers>();
  const [open, setOpen] = useState<boolean>(false);

  function openEditModal(userList?: IUpdateUsers) {
    if (!userList) {
      return;
    }
    setSelectedUser(userList);
    setOpen(true);
  }

  async function submitEditForm(values: IUpdateUsers) {
    if (selectedUser) {
      await sUser.userUpdate(values);
      setFilter((prevState) => ({
        ...prevState,
        page: 1
      }));
    }
    setOpen(false);
    setSelectedUser(undefined);
  }

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      data.result.totalRecords !== undefined ? data.result.totalRecords : prevRowCountState
    );
  }, [data.result.totalRecords, setRowCountState]);

  useEffect(() => {
    setFilter((prevState) => ({
      ...prevState,
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize
    }));
  }, [paginationModel.page, paginationModel.pageSize]);

  const inputProps = {
    inputFormat: 'dd/MM/yyyy'
  };

  return (
    <>
      <Grid container xs={12}>
        <DataGrid
          sx={{ height: '578px', width: '100%', marginTop: '2em' }}
          columns={columns}
          rows={rows}
          loading={loading}
          {...data}
          rowCount={rowCountState}
          pageSizeOptions={[10, 15, 25, 100]}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
        />
      </Grid>
      <Modal
        title={`Editar Usuário: ${selectedUser?.nome || ''} `}
        handleClose={() => {
          setOpen(false);
          setSelectedUser(undefined);
        }}
        isOpen={open}
      >
        {selectedUser && <FormUser userSelect={selectedUser} submit={submitEditForm} />}
      </Modal>
    </>
  );
};

export default UsersBackofficeTable;