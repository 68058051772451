import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import { IAddUserBackofficeProps } from '../interface';

interface IModalAddProps {
  submit: any;
}

const AddUserBackoffice: React.FC<IModalAddProps> = function ({ submit }) {
  return (
    <>
      <Formik
        initialValues={{
          nome: '',
          email: '',
        }}
        onSubmit={(values, { setSubmitting }) => {
          submit(values);
          setSubmitting(false);
        }}
      >
        {({ values, dirty, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid
                container
                spacing={3}
                sx={{ height: '100%', width: '100%' }}
                alignItems="center"
              >
                <Grid item xs={12} sm={4}>
                  <TextField
                    id="nome"
                    name="nome"
                    label="Nome do Usuário"
                    fullWidth
                    variant="outlined"
                    size="small"
                    required
                    defaultValue={values.nome}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    id="email"
                    name="email"
                    label="E-mail"
                    required
                    fullWidth
                    variant="outlined"
                    size="small"
                    defaultValue={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  container
                  marginX="auto"
                  display="flex"
                  alignItems="center"
                >
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting || !dirty}
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default AddUserBackoffice;
