import { Close } from '@mui/icons-material';
import { Box, Dialog, DialogActions, DialogContent, IconButton, Typography, makeStyles } from '@mui/material';
import IModalProps from './interfaces/modalProps';
import './styles/index.css';

const Modal: React.FunctionComponent<IModalProps> = ({
  title,
  subtitle,
  isOpen,
  handleClose,
  actions = undefined,
  children,
  handleSubmit,
  fullWidth = true,
  maxWidth = 'xl',
  isForm = false
}) => {
  
  return (
    <Dialog onClose={handleClose} open={isOpen} fullWidth={fullWidth} maxWidth={maxWidth}>
      <Box className="modal-header" >
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>

        {subtitle && (
          <Typography variant="subtitle1" className="header-subtitle">
            {subtitle}
          </Typography>
        )}

        <Typography variant="h5" className="header-title">
          {title}
        </Typography>
      </Box>

      {isForm ? (
        <form onSubmit={handleSubmit}>
          <DialogContent className="modal-content">{children}</DialogContent>
          {actions && <DialogActions className="modal-actions">{actions}</DialogActions>}
        </form>
      ) : (
        <>
          <DialogContent className="modal-content">{children}</DialogContent>
          {actions && <DialogActions className="modal-actions">{actions}</DialogActions>}
        </>
      )}
    </Dialog>
  );
};

export default Modal;
