import { Grid, Typography } from '@mui/material';
import React from 'react';
// import useUserContext from '../../context/user';
import DefaultLayout from '../../layouts/default';

const Home: React.FC = function () {
  // const { user } = useUserContext();

  return (
    <DefaultLayout>
      <Grid
        container
        sx={{ height: '100%', width: '100%' }}
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h6">Bem Vindo!</Typography>
      </Grid>
    </DefaultLayout>
  );
};

export default Home;
