import axios from 'axios';
import { IUpdatePassword } from '../../interfaces/IUpdatePassword';

const authenticate = async function (username: string, password: string) {
  console.log(username, password)
  return axios.post('/authentication', {
    // credentials: btoa(`${username}:${password}`)
  }, {auth: { username: username, password: password }})
};

const changePassword = async function (data: IUpdatePassword) {
  return axios
    .put('/usuarios', data)
    .then(() => {
      return;
    })
    .catch((error) => {
      throw error;
    });
};

const authenticationService = {
  authenticate,
  changePassword
}

export default authenticationService;