import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import './styles.css';
import { ExpandMore, Logout } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import logo from '../../assets/images/logo.png';
import useUserContext from '../../context/user';
import { useState } from 'react';
import Modal from './../modal/index';
import AlterarSenha from './../alterarSenha/index';
import { useAlertContext } from '../../context/alertContext/alertContext';

interface IHeaderProps {
  children?: React.ReactNode;
}

const Header: React.FC<IHeaderProps> = () => {
  
  const { login, logout } = useUserContext();

  const [open, setOpen] = useState<boolean>(false);

  const { notify } = useAlertContext();

  const [anchorElUsuarios, setAnchorElUsuarios] = React.useState<null | HTMLElement>(null);
  const [anchorElConfiguracoes, setAnchorElConfiguracoes] = React.useState<null | HTMLElement>(null);
  const [anchorElSuporte, setAnchorElSuporte] = React.useState<null | HTMLElement>(null);

  const isOpenUsuarios = Boolean(anchorElUsuarios);
  const isOpenConfiguracoes = Boolean(anchorElConfiguracoes);
  const isOpenSuporte = Boolean(anchorElSuporte);

  const navigate = useNavigate();

  const openUsuarios = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElUsuarios(event.currentTarget);
  };
  const openConfiguracoes = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElConfiguracoes(event.currentTarget);
  };
  const openSuporte = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElSuporte(event.currentTarget);
  };

  const handleClose = () => {
    if(isOpenUsuarios) {
      setAnchorElUsuarios(null)
    } else if (isOpenConfiguracoes) {
      setAnchorElConfiguracoes(null)
    } else {
      setAnchorElSuporte(null)
    }
  };

  const goToPage = (path: string) => {
    navigate(path);
  };

  function logoutButton() {
    logout;
    notify('Usuário desconectado, faça login novamente', 'info', 'medium');
  }

  return (
    <AppBar position="static">
      <Toolbar disableGutters className="header">
        {/* <img src={logo} alt="logo" className="logo" /> */}
        <Box ml={2}>
          <Button color="inherit" onClick={openUsuarios} endIcon={<ExpandMore />}>
            Usuários
          </Button>

          <Menu anchorEl={anchorElUsuarios} open={isOpenUsuarios} onClose={handleClose}>
            <MenuItem onClick={() => goToPage('../usuarios/consultar-usuarios')}>
              Consultar usuários
            </MenuItem>
          </Menu>
        </Box>

        <Box ml={2}>
          <Button color="inherit" onClick={openConfiguracoes} endIcon={<ExpandMore />}>
            Configurações
          </Button>

          <Menu anchorEl={anchorElConfiguracoes} open={isOpenConfiguracoes} onClose={handleClose}>
            <MenuItem onClick={() => goToPage('../configuracoes/configurar-gateway')}>
              Configurar Gateway
            </MenuItem>
          </Menu>
        </Box>

        <Box ml={2}>
          <Button color="inherit" onClick={openSuporte} endIcon={<ExpandMore />}>
            Suporte
          </Button>

          <Menu anchorEl={anchorElSuporte} open={isOpenSuporte} onClose={handleClose}>
            <MenuItem onClick={() => goToPage('../suporte/log')}>
              Histórico de cobranças
            </MenuItem>
          </Menu>
        </Box>

        <Box ml={2}>
          <Button color="inherit" onClick={() => setOpen(true)}>Alterar senha</Button>
        </Box>

        <Box sx={{position:'absolute', right:'1em'}}>
          <div />
          <IconButton size="small" aria-label="Sair" color="inherit" onClick={() => notify('Usuário desconectado, faça login novamente', 'info', 'medium')}>
            <Logout onClick={logout} />
          </IconButton>
        </Box>
      </Toolbar>
      <Modal 
        title="Alterar senha"
        handleClose={() => {
          setOpen(false);
        }}
        isOpen={open}
        maxWidth='sm'
      >
        <AlterarSenha />
      </Modal>
    </AppBar>
  );
};
export default Header;
