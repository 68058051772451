import { createContext, useContext } from 'react';
import IUserContext from './interfaces/userContext';

const UserContext = createContext<IUserContext>({
  user: { nome: '', email: '', ativo: false, primeiroAcesso: false, dataCadastro: null, isAuthenticated: false },
  login: async () => undefined,
  logout: () => undefined,
});

const useUserContext = () => {
  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new Error('UserContextConsumer outside the provider.');
  }

  return userContext;
};

export { UserContext };

export default useUserContext;
