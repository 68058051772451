import axios from 'axios';
import IConfigGatewayFilter from '../../interfaces/IConfigGatewayFilter';
import { IUpdateContaGateway } from '../../components/configuracoes/configurarGateway/interface';

// Retorna informações da ContaGateway
const gatewayGetList = async function (data: IConfigGatewayFilter) {
  const resp = await axios.get('Contas/Gateways', {
    params: data
  });
  return resp.data;
};

const gatewayUpdate = async function (data: IUpdateContaGateway) {
  return axios.put(`Contas/ContaGateway/${data.idGateway}`, data).catch((error) => {
    console.log(error);
  });
};

  const gatewayService = {
    gatewayGetList,
    gatewayUpdate
  }

  export default gatewayService;