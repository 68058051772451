import React, { useCallback, useState } from 'react';
import { UserContext } from './';
import IUser from './interfaces/user';
import IUserContext from './interfaces/userContext';
import sUser from '../../services/userService'
import sAuthentication from './../../services/authenticationService/index';

interface IUserContextProvider {
  children: React.ReactNode;
}

const UserContextProvider: React.FunctionComponent<IUserContextProvider> = function ({ children }) {
  const [user, setUser] = useState<IUser>({ nome: '', email: '', ativo: false, primeiroAcesso: false, dataCadastro: null, isAuthenticated: false });

  const login = useCallback(async (email: string, password: string) => {
  const resposta =  await sAuthentication.authenticate(email, password)
 if(resposta.status === 200){
    const user = await sUser.usuarioGet();
    const data = user.result;
    setUser({ nome: data.nome, email: data.email, ativo: data.ativo, primeiroAcesso: data.primeiroAcesso, dataCadastro: data.primeiroCadastro, ultimaAlteracao: data.ultimaAlteracao, idUsuarioAlteracao: data.idUsuarioAlteracao, ultimoAcesso: data.ultimoAcesso, idUsuarioAlteracaoNavigation: data.idUsuarioAlteracaoNavigation, isAuthenticated: true });
 }

  }, []);

  const logout = useCallback(() => {
    setUser({ nome: '', email: '', ativo: false, primeiroAcesso: false, dataCadastro: null, isAuthenticated: false });
  }, []);

  const data: IUserContext = { user, login, logout };
  return <UserContext.Provider value={data}>{children}</UserContext.Provider>;
};

export default UserContextProvider;