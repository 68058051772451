import React from 'react';
import { IUpdateContaGateway } from '../interface';
import { Formik } from 'formik';
import { Button, Checkbox, FormControlLabel, FormGroup, Grid, TextField } from '@mui/material';
import { useAlertContext } from '../../../../context/alertContext/alertContext';

interface IModalFormProps {
  gatewaySelect: IUpdateContaGateway;
  submit: any;
}

const FormularioGateway: React.FC<IModalFormProps> = function ({ gatewaySelect, submit }) {
  function returnObject(configuracaoGateway: string) {
    console.log(JSON.stringify(configuracaoGateway));

    return JSON.parse(JSON.stringify(configuracaoGateway));
  }

  const alert = useAlertContext();

  return (
    <>
      <Formik
        initialValues={{
          idGateway: gatewaySelect.idGateway,
          ativo: gatewaySelect.ativo,
          prioridade: gatewaySelect.prioridade,
          configuracaoGateway: returnObject(gatewaySelect.configuracaoGateway)
        }}
        onSubmit={(values, { setSubmitting }) => {
          submit(values);
          setSubmitting(false);
        }}
      >
        {({ values, dirty, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid
                container
                spacing={3}
                sx={{ height: '100%', width: '100%' }}
                alignItems="center"
              >
                <Grid item xs={12} sm={4}>
                  <TextField
                    id="prioridade"
                    name="prioridade"
                    label="Prioridade"
                    fullWidth
                    variant="outlined"
                    size="small"
                    required
                    value={values.prioridade}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>

                {values.configuracaoGateway.map((item: any) => (
                  <Grid item xs={12} sm={4} key={item.key}>
                    <TextField
                      id={item.key}
                      name={item.key}
                      label={item.key}
                      fullWidth
                      variant="outlined"
                      size="small"
                      required
                      value={item.value}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                ))}

                <Grid item xs={12} sm={4}>
                  <FormGroup>
                    <FormControlLabel
                      sx={{ width: 'fit-content' }}
                      control={
                        <Checkbox
                          name="ativo"
                          checked={values.ativo}
                          value={values.ativo}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      }
                      label="Ativo"
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12} sm={4} marginX="auto" display="flex" alignItems="center">
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting || !dirty}
                    onClick={() => {
                      alert.notify('Gateway alterado com sucesso', 'success', 'medium');
                    }}
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default FormularioGateway;
