import { Button, Paper, TextField, Typography, Grid, Box } from '@mui/material';
import './styles.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import { validateLoginForm } from './validations';
import { useEffect, useState } from 'react';
import UseUserContext from '../../context/user';
import CircularProgress from '@mui/material/CircularProgress';
import { useAlertContext } from '../../context/alertContext/alertContext';

const LoginPage: React.FunctionComponent = function () {
  const navigate = useNavigate();
  const { login, logout } = UseUserContext();
  const { notify } = useAlertContext();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    logout();
  }, []);

  return (
    <>
      <div className="authentication">
        <h1 className="authentication__title">FlexGateway</h1>

        <div className="authentication__form">
          <Paper sx={{ maxWidth: 650, padding: 4 }}>
            <Typography variant="h4" align="center" className="authentication__form--title">
              Login
            </Typography>
            <Formik
              initialValues={{ email: '', password: '' }}
              validate={validateLoginForm}
              onSubmit={(values, { setSubmitting }) => {
                setLoading(true);
                login(values.email, values.password)
                  .then(() => {
                    const triedAccessPage = (location?.state as any)?.from as string;
                    if (triedAccessPage) {
                      navigate(triedAccessPage);
                    } else {
                      navigate('/home');
                    }
                  })
                  .catch(() => {
                    notify('Usuário ou senha inválidos', 'error', 'medium');
                  })
                  .finally(() => {
                    setSubmitting(false);
                    setLoading(false);
                  });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container className="authentication__form--grid" spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        type="email"
                        name="email"
                        label="E-mail"
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        value={values.email}
                        error={!!errors.email}
                        helperText={errors.email && touched.email && errors.email}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        type="password"
                        name="password"
                        label="Senha"
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        value={values.password}
                        error={!!errors.password}
                        helperText={errors.password && touched.password && errors.password}
                      />
                    </Grid>

                    <Grid item xs={12} container className="authentication__form--grid">
                      <Button
                        className="authentication__form--button"
                        type="submit"
                        variant="contained"
                        disabled={isSubmitting}
                      >
                        Entrar
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
            <Box display="flex" alignItems="center" justifyContent="center">
              {loading && (
                <CircularProgress
                  size={68}
                  sx={{
                    position: 'absolute',
                    marginBottom: 25,
                    zIndex: 1
                  }}
                />
              )}
            </Box>
          </Paper>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
