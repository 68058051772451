import axios from 'axios';

// Retorna informações da Conta
const getConta = async function () {
  const resp = await axios.get('Contas/conta');
  return resp.data;
};

  const contaService = {
    getConta
  }

  export default contaService;