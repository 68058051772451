import WithAxios from '../api/interceptor';
import AlertContextProvider from './alertContext';
import UserContextProvider from './user/userContext';

interface IContextWrapperProps {
  children: React.ReactNode;
}

const ContextWrapper: React.FC<IContextWrapperProps> = function ({ children }) {
  return (
    <AlertContextProvider>
      <WithAxios>
        <UserContextProvider>{children}</UserContextProvider>
      </WithAxios>
    </AlertContextProvider>
  );
};

export default ContextWrapper;
