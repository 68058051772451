/* eslint-disable @typescript-eslint/no-shadow */
import { ToastContainer, toast } from 'react-toastify';
import { AlertContext } from './alertContext';

interface IAlertContextProviderProps {
  children: React.ReactNode;
}

const AlertContextProvider: React.FC<IAlertContextProviderProps> = function ({ children }) {
  const notify = function (
    message: string,
    severity: 'error' | 'info' | 'success' | 'warning',
    duration: 'short' | 'medium' | 'long' | 'always' = 'medium'
  ) {
    let durationInSeconds: number | false = 4000;
    switch (duration) {
      case 'short':
        durationInSeconds = 2000;
        break;

      case 'medium':
        durationInSeconds = 4000;
        break;

      case 'long':
        durationInSeconds = 6000;
        break;

      case 'always':
        durationInSeconds = false;
        break;

      default:
        durationInSeconds = 4000;
    }

    toast(message, {
      type: severity,
      autoClose: durationInSeconds,
      hideProgressBar: true,
      closeOnClick: true,
      theme: 'colored',
      position: 'bottom-right'
    });
  };

  return (
    <div>
      <AlertContext.Provider value={{ notify }}>
        {children}
        <ToastContainer />
      </AlertContext.Provider>
    </div>
  );
};

export default AlertContextProvider;
