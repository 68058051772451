const mascaraCpf = (valor: any) => {
  return valor?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
};
const mascaraCnpj = (valor: any) => {
  return valor?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3.$4-$5');
};

const formatDate = (input: any) => {
  const datePart = input.match(/\d+/g),
    year = datePart[0],
    month = datePart[1],
    day = datePart[2],
    hour = datePart[3],
    minutes = datePart[4],
    seconds = datePart[5];
  return day + '/' + month + '/' + year + ' ' + hour + ':' + minutes + ':' + seconds;
};

export { mascaraCpf, mascaraCnpj, formatDate };
