import React, { useCallback, useEffect, useState } from 'react';
import DefaultLayout from '../../../layouts/default';
import { Button, Grid, Paper, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import ICobrancaFilter from '../../../interfaces/ICobrancaFilter';
import ICobrancaResult from '../../../interfaces/ICobrancaResult';
import LogTable from './../../../components/suporte/log/index';
import sLog from '../../../services/logService';
import './style.css';

const Log: React.FunctionComponent = function () {
  const [filter, setFilter] = useState<ICobrancaFilter>({
    situacao: '',
    token: '',
    dataUltimaTentativaStart: '',
    dataUltimaTentativaEnd: '',
    page: 1,
    pageSize: 10
  });

  const [loading, setLoading] = useState<boolean>(false);

  const [data, setData] = useState<ICobrancaResult>({
    page: 1,
    pages: 1,
    pageSize: 10,
    results: [],
    totalRecords: 0
  });

  useEffect(() => {
    handleSubmit(filter);
  }, [filter]);

  const handleSubmit = useCallback(
    async (filter: any) => {
      console.log(filter);
      setLoading(true);
      sLog
        .getCobrancas(filter)
        .then((data) => {
          console.log(filter);
          setData(data.result);
        })
        .finally(() => setLoading(false));
    },
    [filter]
  );

  const inputProps = {
    inputFormat: 'dd/MM/yyyy'
  };

  return (
    <>
      <DefaultLayout>
        <Paper sx={{ padding: 4 }} className="consultar-usuarios__title">
          <Typography variant="h6" align="center" sx={{ padding: 1 }}>
            Histórico de cobranças
          </Typography>
          <div>
            <Formik
              initialValues={{
                situacao: '',
                token: '',
                dataUltimaTentativaStart: '',
                dataUltimaTentativaEnd: '',
                page: 1,
                pageSize: 10
              }}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                setFilter((prevState) => ({
                  ...prevState,
                  page: 1,
                  pageSize: 10,
                  situacao: values.situacao,
                  token: values.token,
                  dataUltimaTentativaStart: values.dataUltimaTentativaStart,
                  dataUltimaTentativaEnd: values.dataUltimaTentativaEnd
                }));
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                <form onSubmit={handleSubmit} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      height: '100%',
                      width: '80%',
                      display: 'flex',
                      justifyContent: 'center',
                      margin: '0.5em 0'
                    }}
                    alignItems="center"
                  >
                    <Grid item xs={12} sm={3}>
                      <TextField
                        id="situacao"
                        name="situacao"
                        label="Situação"
                        fullWidth
                        variant="outlined"
                        size="small"
                        value={values.situacao}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.situacao}
                        helperText={
                          errors.situacao &&
                          touched.situacao &&
                          errors.situacao
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        id="token"
                        name="token"
                        label="Token"
                        fullWidth
                        variant="outlined"
                        size="small"
                        value={values.token}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.token}
                        helperText={errors.token && touched.token && errors.token}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        // sx={{ marginRight:'2em' }}
                        defaultValue={new Date().toISOString().substring(0, 10)}
                        label="Data Inicial"
                        id="dataUltimaTentativaStart"
                        name="dataUltimaTentativaStart"
                        size="small"
                        type="date"
                        inputProps={inputProps}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.dataUltimaTentativaStart}
                        helperText={
                          errors.dataUltimaTentativaStart && touched.dataUltimaTentativaStart && errors.dataUltimaTentativaStart
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        // sx={{ maxWidth: 140 }}
                        label="Data Final"
                        id="dataUltimaTentativaEnd"
                        name="dataUltimaTentativaEnd"
                        size="small"
                        type="date"
                        inputProps={inputProps}
                        defaultValue={new Date().toISOString().substring(0, 10)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.dataUltimaTentativaEnd}
                        helperText={
                          errors.dataUltimaTentativaEnd && touched.dataUltimaTentativaEnd && errors.dataUltimaTentativaEnd
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Button
                        type="submit"
                        variant="contained"
                        className="consultarUsuarios-filter-btn"
                      >
                        Pesquisar
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </div>
          <LogTable data={data} setFilter={setFilter} loading={loading} />
        </Paper>
      </DefaultLayout>
    </>
  );
};

export default Log;
