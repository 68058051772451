import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { Formik } from 'formik';
import { IFormUserBackofficeProps, IUpdateUsers } from './../interface/index';
import sUser from '../../../services/userService';
import { useAlertContext } from '../../../context/alertContext/alertContext';

interface IModalFormProps {
  userSelect: IUpdateUsers;
  submit: any;
}

const FormUser: React.FC<IModalFormProps> = function ({ userSelect, submit }) {

const alert = useAlertContext();

  async function resetPasswordBackoffice() {
    await sUser.resetPassword(userSelect.idUsuario);
    alert.notify('Senha resetada com sucesso', 'success', 'medium');
  }

  return (
    <>
      <Formik
        initialValues={{
          idUsuario: userSelect.idUsuario,
          nome: userSelect.nome,
          email: userSelect.email,
          senhaInicial: userSelect.senhaInicial,
          ativo: userSelect.ativo
        }}
        onSubmit={(values, { setSubmitting }) => {
          submit(values);
          setSubmitting(false);
        }}
      >
        {({ values, dirty, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid
                container
                spacing={3}
                sx={{ height: '100%', width: '100%' }}
                alignItems="center"
              >
                <Grid item xs={12} sm={4}>
                  <TextField
                    id="userName"
                    name="nome"
                    label="Nome do Usuário"
                    fullWidth
                    variant="outlined"
                    size="small"
                    required
                    value={values.nome}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    id="userEmail"
                    name="email"
                    label="E-mail"
                    required
                    value={values.email}
                    fullWidth
                    variant="outlined"
                    size="small"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={12} sm={1}>
                  <FormGroup>
                    <FormControlLabel
                      sx={{ width: 'fit-content' }}
                      control={
                        <Checkbox
                          name="ativo"
                          checked={values.ativo}
                          value={values.ativo}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      }
                      label="Ativo"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Button
                    fullWidth
                    name="senhaInicial"
                    variant="contained"
                    value={values.senhaInicial}
                    onClick={resetPasswordBackoffice}
                  >
                    Nova senha inicial
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  container
                  marginX="auto"
                  display="flex"
                  alignItems="center"
                >
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting || !dirty}
                    onClick={() => {alert.notify('Usuário alterado com sucesso', 'success', 'medium')}}
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default FormUser;
