function validateNewPassword(password: string) {
  if (password.length < 5) {
    console.log('A senha precisa ter mais de 5 caracteres');
    return false;
  } else {
    return true;
  }
}

function validateConfirm(passwordNew: string, passwordConfirm: string) {
  if (passwordConfirm !== passwordNew) {
    console.log('As senhas não são iguais');
    return false;
  } else {
    return true;
  }
}

function submitConfirm(passwordNew: string, passwordConfirm: string) {
  if (passwordConfirm !== passwordNew || passwordNew.length < 5) {
    return false;
  } else {
    return true;
  }
}

export { validateConfirm, validateNewPassword, submitConfirm };
