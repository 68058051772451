import React, { useMemo } from 'react';
import axios from 'axios';
import Message from '../config/message';
import { httpStatusCode, storageParams } from '../config/enum';
import { buildValidationErros } from './utils';
import { useAlertContext } from '../context/alertContext/alertContext';
import config from '../config/config';
import { useNavigate } from 'react-router-dom';

interface IWithAxiosProps {
  children: React.ReactNode;
}

let axiosInterceptorResponse: any = null;

// Possibilita utilizar os React Hooks no tratamento de exceções.
const WithAxios: React.FunctionComponent<IWithAxiosProps> = function ({ children }) {
  const navigate = useNavigate();
  const { notify } = useAlertContext();

  useMemo(() => {
    axios.defaults.baseURL = config.apiUrlBase;

    if (!!axiosInterceptorResponse || axiosInterceptorResponse === 0) {
      axios.interceptors.response.eject(axiosInterceptorResponse);
    }

    axiosInterceptorResponse = axios.interceptors.response.use(
      function (response) {
        // hideLoading();
        // Atualiza Token JWT
        const tokenJwt = response.headers['x-access-token'];
        const csrfTkn = response.headers['x-csrf-token'];
        if (tokenJwt) {
          axios.defaults.headers.common['Authorization'] = `Bearer ${tokenJwt}`;
          sessionStorage.setItem(storageParams.tokenJwt, tokenJwt); // Salva token JWT no session storage
        }

        if (csrfTkn) {
          axios.defaults.headers.get['X-Csrf-Token'] = csrfTkn;
          axios.defaults.headers.post['X-Csrf-Token'] = csrfTkn;
          axios.defaults.headers.put['X-Csrf-Token'] = csrfTkn;
          axios.defaults.headers.delete['X-Csrf-Token'] = csrfTkn;
          axios.defaults.headers.patch['X-Csrf-Token'] = csrfTkn;
        }

        if (response.data.message) {
          notify(response.data.message, response.data.status || 'success');
        }

        return Promise.resolve(response);
      },
      function (error) {
        if (error.message === Message.oldRequestCanceled) {
          return Promise.reject(error);
        }
        // hideLoading();
        if (error.message === 'isOffline') {
          return Promise.reject(error);
        }

        // let message = Message.defaultError;
        let message = '';
        let status: 'error' | 'info' | 'warning' | 'success' = 'error';

        if (error.code === 'ECONNABORTED') {
          message = Message.serverTimeout;
        }

        if (error.response) {
          switch (error.response.status) {
            case httpStatusCode.Unauthorized:
              // case httpStatusCode.Forbidden:
              axios.defaults.headers.common['Authorization'] = false;
              navigate('/');
              break;

            case httpStatusCode.BusinessException:
              status = 'warning';
              break;
          }

          // Recupera mensagem enviada pela API
          if (error.response.data && error.response.data.message) {
            message = error.response.data.message;
          }

          if (['info', 'warning', 'success', 'error'].includes(error.response.data.status)) {
            status = error.response.data.status;
          }

          // Erros de validação
          if (error.response.data.errors) {
            message = buildValidationErros(error.response.data.errors);
            status = 'error';
          }

          if (message) {
            notify(message, status);
          }
          
          return Promise.reject(error);
        }

        if (message) {
          notify(message, status);
        }

        return Promise.reject(error);
      }
    );
  }, []);

  return <>{children}</>;
};

export default WithAxios;
