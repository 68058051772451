import axios, { CancelTokenSource } from 'axios';
import { IAddUserBackofficeProps, ICreateUsers, IUpdateUsers, IUsers } from '../../components/userBackoffice/interface';
import IUsuarioModel from '../../interfaces/IUsuarioModel';
import IListUserBackofficeFilter from '../../interfaces/listUserBackofficeFilter';
import IListUserBackofficeResult from '../../interfaces/listUserBackofficeResult';
import Message from '../../config/message';

// Usuario
const usuarioGet = async function () {
  const resp = await axios.get('/usuarios');
  return resp.data;
};

const resetPassword = async function (idUsuario:number) {
  const resp = await axios.put(`/usuarios/${idUsuario}/resetarSenha`);
  return resp.data;
}

// UsuarioConta
const userList = async function (filter:IListUserBackofficeFilter): Promise<IListUserBackofficeResult> {

  const resp = await axios.get('/contas/usuarios', {
    params: filter
  });
  return resp.data;
};

const userGet = async function (data: IUsers) {
  const resp = await axios.get(`/contas/usuario/${data.idUsuario}`);
  return resp.data;
};

const userUpdate = async function (data: IUpdateUsers) {
    return axios.put(`contas/usuario/${data.idUsuario}`, data).catch((error) => {
      console.log(error);
    });
  };

  const userDelete = async function (data: IUsers) {
    return axios.delete(`contas/usuario/${data.idUsuario}`).catch((error) => {
      console.log(error);
    });
  };

  const userCreate = async function (data: IAddUserBackofficeProps) {
    return axios.post('/contas/usuario', {nome: data.nome, email: data.email}).catch((error) => {
      console.log(error);
    });
  };
  
  const userGetList = async function () {
    const resp = await axios.get('/contas');
    return resp.data;
  };

const userService = {
    userGetList,
    resetPassword,
    usuarioGet,
    userList,
    userGet,
    userUpdate,
    userDelete,
    userCreate
}

export default userService;