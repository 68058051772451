import { Button, Grid, Paper, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import IListUserBackofficeFilter from '../../../interfaces/listUserBackofficeFilter';
import IListUserBackofficeResult from '../../../interfaces/listUserBackofficeResult';
import DefaultLayout from './../../../layouts/default/index';
import UsersBackofficeTable from './../../../components/userBackoffice/table/index';
import sUser from '../../../services/userService';
import Modal from '../../../components/modal';
import AddUserBackoffice from '../../../components/userBackoffice/novoUsuario';
import { IAddUserBackofficeProps } from '../../../components/userBackoffice/interface';
import './style.css';

const ConsultarUsuarios: React.FunctionComponent = function () {
  const [filter, setFilter] = useState<IListUserBackofficeFilter>({
    nome: '',
    email: '',
    page: 1,
    pageSize: 10
  });

  const [loading, setLoading] = useState<boolean>(false);

  const [data, setData] = useState<IListUserBackofficeResult>({
    result: {
      page: 0,
      pages: 1,
      pageSize: 10,
      results: [],
      totalRecords: 0
    }
  });

  useEffect(() => {
    handleSubmit(filter);
  }, [filter]);

  const handleSubmit = useCallback(
    async (filter: any) => {
      setLoading(true);
      sUser
        .userList(filter)
        .then((data) => {
          console.log(data);
          setData(data);
        })
        .finally(() => setLoading(false));
    },
    [filter]
  );

  const [open, setOpen] = useState<boolean>(false);

  function openAddModal() {
    setOpen(true);
  }

  async function createUserBackoffice(values: IAddUserBackofficeProps) {
    if (values?.nome?.length >= 3) {
      sUser.userCreate(values).then((data) => {
        setFilter((prevState) => ({
          ...prevState,
          page: 1
        }));
      });

      setOpen(false);
    } else {
      return console.log('Digite um nome válido.');
    }
  }

  return (
    <DefaultLayout>
      <Paper sx={{ padding: 4 }} className="consultar-usuarios__title">
        <Typography variant="h6" align="center" sx={{ padding: 1 }}>
          Consultar Usuários
        </Typography>
        <div>
          <Formik
            initialValues={{
              nome: '',
              email: ''
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              setFilter((prevState) => ({
                ...prevState,
                page: 1,
                pageSize: 10,
                nome: values.nome,
                email: values.email
              }));
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Grid
                  container
                  spacing={3}
                  sx={{ height: '100%', width: '100%' }}
                  alignItems="center"
                >
                  <Grid item xs={12} sm={4}>
                    <TextField
                      id="userName"
                      name="nome"
                      label="Nome do Usuário"
                      fullWidth
                      variant="outlined"
                      size="small"
                      value={values.nome}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!errors.nome}
                      helperText={errors.nome && touched.nome && errors.nome}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      id="userEmail"
                      name="email"
                      label="E-mail"
                      fullWidth
                      variant="outlined"
                      size="small"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!errors.email}
                      helperText={errors.email && touched.email && errors.email}
                    />
                  </Grid>
                  <Grid item container xs={12} sm={3} spacing={1}>
                    <Grid item>
                      <Button
                        type="submit"
                        variant="contained"
                        className="consultarUsuarios-filter-btn"
                      >
                        Pesquisar
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        className="consultarUsuarios-filter-btn"
                        onClick={openAddModal}
                      >
                        Novo usuário
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </div>
        <UsersBackofficeTable data={data} setFilter={setFilter} loading={loading} />

        <Modal
          title="Novo usuário:"
          handleClose={() => {
            setOpen(false);
          }}
          isOpen={open}
        >
          <AddUserBackoffice submit={createUserBackoffice} />
        </Modal>
      </Paper>
    </DefaultLayout>
  );
};

export default ConsultarUsuarios;
