import ILoginForm from '../interfaces';

function validateLoginForm(values: ILoginForm) {
  const errors: ILoginForm = {};
  if (!values.email) {
    errors.email = 'Campo e-mail é obrigatório';
  }
  if (!values.password) {
    errors.password = 'Campo senha é obrigatório';
  }
  return errors;
}

export { validateLoginForm };
