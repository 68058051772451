import React, { useCallback, useEffect, useState } from 'react';
import DefaultLayout from '../../../layouts/default';
import { Button, Grid, Paper, TextField, Typography, InputLabel, Modal } from '@mui/material';
import { Formik } from 'formik';
import IConfigGatewayFilter from '../../../interfaces/IConfigGatewayFilter';
import './style.css';
import AddUserBackoffice from '../../../components/userBackoffice/novoUsuario';
import IListUserBackofficeFilter from '../../../interfaces/listUserBackofficeFilter';
import IListUserBackofficeResult from '../../../interfaces/listUserBackofficeResult';
import UsersBackofficeTable from '../../../components/userBackoffice/table';
import sGateway from '../../../services/contaGatewayService';
import sConta from '../../../services/contaService';
import ConfigurarGatewayTable from '../../../components/configuracoes/configurarGateway';
import IConfigGatewayResult from '../../../interfaces/IConfigGatewayResult';
import IContaModel from '../../../interfaces/IContaModel';

const ConfigurarGateway: React.FunctionComponent = function () {
  const [filter, setFilter] = useState<IConfigGatewayFilter>({
    nome: '',
    page: 1,
    pageSize: 5
  });

  const [conta, setConta] = useState<IContaModel>({
    chaveApi: '',
    segredo: '',
    dominio: '',
    situacao: ''
  });

  const [loading, setLoading] = useState<boolean>(false);

  const [data, setData] = useState<IConfigGatewayResult>({
    page: 1,
    pages: 1,
    pageSize: 5,
    results: [],
    totalRecords: 0
  });

  useEffect(() => {
    handleSubmit(filter);
  }, [filter]);

  const handleSubmit = useCallback(
    async (filter: any) => {
      setLoading(true);
      sGateway.gatewayGetList(filter).then((data) => {
        setData(data.result);
      });
      sConta
        .getConta()
        .then((data) => {
          setConta(data.result);
        })
        .finally(() => setLoading(false));
    },
    [filter]
  );

  return (
    <DefaultLayout>
      <Paper sx={{ padding: 4 }} className="consultar-usuarios__title">
        <Typography variant="h6" align="center" sx={{ padding: 1 }}>
          Informações da conta
        </Typography>

        <Grid container className="infomacoesConta__grid">
          <Grid item container spacing={3} className="informacoesConta__grid--item">
            <Grid item xs={12} sm={4}>
              <InputLabel htmlFor="chaveapi">ChaveApi</InputLabel>
              <TextField
                className="informacoesConta__input"
                id="chaveapi"
                value={conta.chaveApi}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel htmlFor="segredo">Segredo</InputLabel>
              <TextField
                className="informacoesConta__input"
                id="segredo"
                value={conta.segredo}
                disabled
              />
            </Grid>
            <Grid item container xs={12} spacing={3} className="informacoesConta__grid--item">
              <Grid item xs={12} sm={4}>
                <InputLabel htmlFor="dominio">Domínio</InputLabel>
                <TextField
                  className="informacoesConta__input"
                  id="dominio"
                  value={conta.dominio}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel htmlFor="situacao">Situação</InputLabel>
                <TextField
                  className="informacoesConta__input"
                  id="situacao"
                  value={conta.situacao}
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Typography variant="h6" align="center" sx={{ padding: 1, margin: 3 }}>
          Configurar Gateway
        </Typography>
        <div>
          <Formik
            initialValues={{
              nome: '',
              page: 1,
              pageSize: 5
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              setFilter((prevState) => ({
                ...prevState,
                page: 1,
                pageSize: 5,
                nome: values.nome
              }));
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Grid
                  container
                  spacing={3}
                  sx={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '10px' }}
                  alignItems="center"
                >
                  <Grid item xs={12} sm={4}>
                    <TextField
                      id="nomeGateway"
                      name="nome"
                      label="Nome do Gateway"
                      fullWidth
                      variant="outlined"
                      size="small"
                      value={values.nome}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!errors.nome}
                      helperText={errors.nome && touched.nome && errors.nome}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      className="consultarUsuarios-filter-btn"
                    >
                      Pesquisar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </div>
        <br />

        <ConfigurarGatewayTable data={data} setFilter={setFilter} filter={filter} loading={loading} />

      </Paper>
    </DefaultLayout>
  );
};

export default ConfigurarGateway;
