import React, { useEffect, useState } from 'react';
import ICobrancaResult from '../../../interfaces/ICobrancaResult';
import ICobrancaFilter from '../../../interfaces/ICobrancaFilter';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridValueFormatterParams
} from '@mui/x-data-grid';
import { formatDate } from '../../validators/fomatter';
import { Box } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Modal from '../../modal';
import ICobrancaModel from '../../../interfaces/ICobrancaModel';
import CobrancaGatewayTable from './formulario';

interface ILogTableProps {
  data: ICobrancaResult;
  setFilter: React.Dispatch<React.SetStateAction<ICobrancaFilter>>;
  loading: boolean;
}

interface IRowLog {
  identificadorCobranca: string;
  dataCobranca: Date;
  situacaoCobranca: string;
  dataUltimaTentativa: Date;
  numeroTentativa: number;
  token: string;
}

const LogTable: React.FC<ILogTableProps> = function ({ data, setFilter, loading }) {
  const rows: IRowLog[] = data.results.map((item: any) => ({
    identificadorCobranca: item.identificadorCobranca,
    dataCobranca: item.dataCobranca,
    situacaoCobranca: item.situacao,
    dataUltimaTentativa: item.dataUltimaTentativa,
    numeroTentativa: item.numeroTentativa,
    token: item.tokenCobranca.token
  }));

  const columns: GridColDef<IRowLog>[] = [
    {
      field: 'actions',
      headerClassName: 'super-app-theme--header',
      type: 'actions',
      width: 100,
      headerName: 'Ações',
      getActions: (params) => [
        <>
          <GridActionsCellItem
            key={params.id}
            icon={<VisibilityIcon />}
            label="Editar"
            onClick={() => {
              const cobrancaSelecionada = data.results.find(
                (item: any) => item.identificadorCobranca == params.id
              )!;
              openEditModal({
                identificadorCobranca: cobrancaSelecionada.identificadorCobranca,
                valor: cobrancaSelecionada.valor,
                dataCobranca: cobrancaSelecionada.dataCobranca,
                situacao: cobrancaSelecionada.situacao,
                dataPagamento: cobrancaSelecionada.dataPagamento,
                dataUltimaTentativa: cobrancaSelecionada.dataUltimaTentativa,
                numeroTentativa: cobrancaSelecionada.numeroTentativa,
                informacaoAdicional: cobrancaSelecionada.informacaoAdicional,
                tokenCobranca: {
                  token: cobrancaSelecionada.tokenCobranca.token,
                  dataGeracao: cobrancaSelecionada.tokenCobranca.dataGeracao,
                  bandeiraCartao: cobrancaSelecionada.tokenCobranca.bandeiraCartao,
                  situacao: cobrancaSelecionada.tokenCobranca.situacao
                },
                cobrancaGateway: cobrancaSelecionada.cobrancaGateway
              });
            }}
          />
        </>
      ]
    },
    {
      field: 'identificadorCobranca',
      headerName: 'Identificador Cobrança',
      headerClassName: 'super-app-theme--header',
      minWidth: 300,
      flex:1,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'dataCobranca',
      headerName: 'Data da Cobrança',
      headerClassName: 'super-app-theme--header',
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        if (params.value != null) {
          return formatDate(params.value);
        }
      },
      minWidth: 175,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'situacaoCobranca',
      headerName: 'Situação da Cobrança',
      headerClassName: 'super-app-theme--header',
      minWidth: 175,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'dataUltimaTentativa',
      headerName: 'Última Tentativa',
      headerClassName: 'super-app-theme--header',
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        if (params.value != null) {
          return formatDate(params.value);
        }
      },
      minWidth: 200,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'numeroTentativa',
      headerName: 'N° Tentativa',
      headerClassName: 'super-app-theme--header',
      minWidth: 125,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'token',
      headerName: 'Token Gateway',
      headerClassName: 'super-app-theme--header',
      minWidth: 360,
      flex:1,
      align: 'center',
      headerAlign: 'center'
    }
  ];

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0
  });

  const [rowCountState, setRowCountState] = React.useState(data.totalRecords || 0);

  const [selectedLog, setSelectedLog] = useState<ICobrancaModel>();
  const [open, setOpen] = useState<boolean>(false);

  function openEditModal(cobrancaList?: ICobrancaModel) {
    console.log(cobrancaList);
    if (!cobrancaList) {
      return;
    }
    setSelectedLog(cobrancaList);
    setOpen(true);
  }

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      data.totalRecords !== undefined ? data.totalRecords : prevRowCountState
    );
  }, [data.totalRecords, setRowCountState]);

  useEffect(() => {
    setFilter((prevState) => ({
      ...prevState,
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize
    }));
  }, [paginationModel.page, paginationModel.pageSize]);

  return (
    <>
        <DataGrid
          sx={{height:'600px', width:'100%', marginTop:'2em'}}
          columns={columns}
          rows={rows}
          loading={loading}
          {...data}
          rowCount={rowCountState}
          pageSizeOptions={[10, 15, 25, 100]}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          getRowId={(row) => row.identificadorCobranca}
        />
      <Modal
        title={'Informações do registro'}
        handleClose={() => {
          setOpen(false);
        }}
        isOpen={open}
      >
        {selectedLog && <CobrancaGatewayTable data={selectedLog} />}
      </Modal>
    </>
  );
};

export default LogTable;
