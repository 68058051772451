import { Button, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IUpdatePassword } from '../../interfaces/IUpdatePassword';
import { submitConfirm } from './validator';
import sAuthentication from '../../services/authenticationService';
import sUser from '../../services/userService';
import useUserContext from '../../context/user';
import { useAlertContext } from '../../context/alertContext/alertContext';

const AlterarSenha: React.FC = function () {

  const [senha, setSenha] = useState<string>('');

  const [novaSenha, setNovaSenha] = useState<string>('');

  const [confirmacaoNovaSenha, setConfirmacaoNovaSenha] = useState<string>('');

  const [isNull, setIsNull] = useState<boolean>(false);

  const { login, logout } = useUserContext();

  const alert = useAlertContext();
  
  useEffect(() => {
    setIsNull(submitConfirm(confirmacaoNovaSenha, novaSenha));
  }, [novaSenha, confirmacaoNovaSenha]);

  const handleSubmit = async function (e:any) {
    e.preventDefault();
    const data: IUpdatePassword = {
      senha: senha,
      novaSenha: novaSenha,
      confirmacaoNovaSenha: confirmacaoNovaSenha
    };

    await sAuthentication
      .changePassword(data)
      .then(() => {
        alert.notify('Senha alterada com sucesso', 'success', 'medium');
        logout();
      })
      .catch((e) => {
        console.log(e)
        if(novaSenha !== confirmacaoNovaSenha) {
          alert.notify('"Nova senha" e "Confirmar nova senha" não conferem', 'error', 'medium');
        } else {
          alert.notify('Senha atual inválida', 'error', 'medium');
        }
      });
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <TextField
          type="password"
          name="senha"
          label="Senha atual"
          variant="outlined"
          onChange={(e) => {
            setSenha(e.target.value)
          }}
          sx={{ width: '70%', marginTop: '2%' }}
        />
        <TextField
          type="password"
          name="novaSenha"
          label="Nova senha"
          variant="outlined"
          inputProps={{ minLength: 6, maxLength: 15 }}
          onChange={(e) => {
            setNovaSenha(e.target.value)
          }}
          sx={{ width: '70%', marginTop: '2%' }}
        />
        <TextField
          type="password"
          name="confirmacaoNovaSenha"
          label="Confirmar nova senha"
          variant="outlined"
          inputProps={{ minLength: 6, maxLength: 15 }}
          onChange={(e) => {
            setConfirmacaoNovaSenha(e.target.value)
          }}
          sx={{ width: '70%', marginTop: '2%' }}
        />
        <Button variant="contained" type="submit" sx={{ width: '40%', marginTop: '3%', marginBottom: '3%', backgroundColor: '#5757B1' }}>
          ENVIAR
        </Button>
      </form>
    </>
  );
};

export default AlterarSenha;
