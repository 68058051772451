import { DataGrid, GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import { Edit } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import IConfigGatewayResult from '../../../interfaces/IConfigGatewayResult';
import IConfigGatewayFilter from '../../../interfaces/IConfigGatewayFilter';
import './style.css';
import Modal from './../../modal/index';
import { IUpdateContaGateway } from './interface';
import FormularioGateway from './formulario/index';
import sGateway from '../../../services/contaGatewayService';

interface IUsuarioConfigGatewayProps {
  data: IConfigGatewayResult;
  setFilter: React.Dispatch<React.SetStateAction<IConfigGatewayFilter>>;
  filter: IConfigGatewayFilter;
  loading: boolean;
}

interface IRowGateway {
  nome: string;
  prioridade: number;
  ativo: string;
  configuracaoGateway: string;
}

const ConfigurarGatewayTable: React.FC<IUsuarioConfigGatewayProps> = function ({
  data,
  setFilter,
  filter,
  loading
}) {
  const [selectedGateway, setSelectedGateway] = useState<IUpdateContaGateway>();

  const [open, setOpen] = useState<boolean>(false);

  function openModal(gatewayList?: IUpdateContaGateway) {
    if (!gatewayList) {
      return;
    }
    console.log(gatewayList);
    setSelectedGateway(gatewayList);
    setOpen(true);
  }

  async function submitEditForm(values: IUpdateContaGateway) {
    if (selectedGateway) {
      console.log(values);
      await sGateway.gatewayUpdate(values);
      setFilter((prevState) => ({
        ...prevState,
        page: 1
      }));
    }
    setOpen(false);
    setSelectedGateway(undefined);
  }

  const rows: IRowGateway[] = data.results.map((item: any) => ({
    id: item.gateway.id,
    nome: item.gateway.nome,
    prioridade: item.prioridade,
    ativo: item.ativo || item.gateway.ativo ? 'Ativo' : 'Inativo',
    configuracaoGateway: JSON.stringify(item.configuracaoGateway)
  }));

  const columns: GridColDef<IRowGateway>[] = [
    {
      field: 'actions',
      headerClassName: 'super-app-theme--header',
      type: 'actions',
      width: 100,
      headerName: 'Ações',
      getActions: (params) => [
        <>
          <GridActionsCellItem
            key={params.id}
            icon={<Edit />}
            label="Editar"
            onClick={() => {
              const gatewaySelecionado = data.results.find(
                (item: any) => item.gateway.id == params.id
              )!;
              openModal({
                idGateway: gatewaySelecionado.gateway.id,
                ativo: gatewaySelecionado.ativo,
                prioridade: gatewaySelecionado.prioridade,
                configuracaoGateway: gatewaySelecionado.configuracaoGateway
              });
            }}
          />
        </>
      ]
    },
    {
      field: 'nome',
      headerName: 'Nome',
      headerClassName: 'super-app-theme--header',
      minWidth: 300,
      flex:1,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'prioridade',
      headerName: 'Prioridade',
      headerClassName: 'super-app-theme--header',
      minWidth: 150,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'ativo',
      headerName: 'Ativo',
      headerClassName: 'super-app-theme--header',
      minWidth: 164,
      align: 'center',
      headerAlign: 'center'
    }
    // {
    //   field: 'configuracaoGateway',
    //   headerName: 'Configurações',
    //   headerClassName: 'super-app-theme--header',
    //   minWidth: 600,
    //   align: 'center',
    //   headerAlign: 'center'
    // }
  ];

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 5,
    page: 0
  });

  const [rowCountState, setRowCountState] = React.useState(data.totalRecords || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      data.totalRecords !== undefined ? data.totalRecords : prevRowCountState
    );
  }, [data.totalRecords, setRowCountState]);

  useEffect(() => {
    setFilter((prevState) => ({
      ...prevState,
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize
    }));
  }, [paginationModel.page, paginationModel.pageSize]);

  return (
    <>
      <Grid xs={6} sx={{ margin: 'auto' }}>
        <DataGrid
          sx={{
            height: '380px',
          }}
          columns={columns}
          rows={rows}
          loading={loading}
          {...data}
          rowCount={rowCountState}
          pageSizeOptions={[10, 15, 25, 100]}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
        />
      </Grid>
      <Modal
        title="Vincular Gateway"
        handleClose={() => {
          setOpen(false);
        }}
        isOpen={open}
      >
        {selectedGateway && (
          <FormularioGateway gatewaySelect={selectedGateway} submit={submitEditForm} />
        )}
      </Modal>
    </>
  );
};

export default ConfigurarGatewayTable;
